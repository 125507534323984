import React, { Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { CognitoProps } from "../App";
import { ErrorNotFound } from "../atoms/ErrorNotFound";
import { ErrorAlert } from "../components/Alert";
import BottomNavigation from "../components/BottomNavigation";
import Loading from "../components/Loading";
import { isError } from "../helper/error";
import { useAuth } from "../hooks/useAuth";
import useApi from "../utils/useApi";
import { BreadcrumbProps } from "./MainRoute";

const Home = React.lazy(() => import("../views/residence/Home"));

interface Props extends CognitoProps, BreadcrumbProps {}

const ResidenceRoute: React.FC<Props> = (props: Props) => {
  const { signOut } = props;

  const user = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.groups?.some((group) => group === "admin")) navigate("/admin");
  }, [user]);

  const responseUser = useApi("/getUser", {});

  if (responseUser === "loading") {
    return <Loading />;
  }

  if (isError(responseUser)) {
    return <ErrorAlert message={responseUser.message} />;
  }
  return (
    <Suspense fallback={<Loading />}>
      <main>
        <Routes>
          <Route
            path={`/`}
            element={
              <Home
                {...props}
                user={responseUser}
                refetchUser={responseUser.refetch}
              />
            }
          />
          <Route path="*" element={<ErrorNotFound />} />
        </Routes>
      </main>

      <BottomNavigation />
    </Suspense>
  );
};

export default ResidenceRoute;
