import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { CognitoProps } from "../App";
import { ErrorNotFound } from "../atoms/ErrorNotFound";
import { Logout } from "../atoms/Logout";
import Loading from "../components/Loading";
import { Path } from "../molecules/Breadcrumb";
import AdminRoute from "./AdminRoute";
import ResidenceRoute from "./ResidenceRoute";

export interface BreadcrumbProps {
  baseBreadcrumb: Path[];
  baseUrl: string;
}

interface Props extends CognitoProps {}

const MainRoute: React.FC<Props> = (props: Props) => {
  const baseBreadcrumb: Path[] = [];
  const baseUrl = "/";

  const breadcrumbs = {
    baseBreadcrumb,
    baseUrl,
  };

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route
          path="/"
          element={<ResidenceRoute {...props} {...breadcrumbs} />}
        />
        <Route
          path="/admin/*"
          element={<AdminRoute baseBreadcrumb={[]} baseUrl={""} {...props} />}
        />
        <Route path="/logout" element={<Logout {...props} />} />
        <Route path="*" element={<ErrorNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
