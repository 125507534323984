import React, { Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { CognitoProps } from "../App";
import { ErrorNotFound } from "../atoms/ErrorNotFound";
import { ErrorAlert } from "../components/Alert";
import Header from "../components/Header";
import Loading from "../components/Loading";
import Sidebar from "../components/Sidebar";
import { isError } from "../helper/error";
import { useAuth } from "../hooks/useAuth";
import useApi from "../utils/useApi";
import { BreadcrumbProps } from "./MainRoute";

const AdminHome = React.lazy(() => import("../views/admin/AdminHome"));
const Residences = React.lazy(() => import("../views/admin/Residences"));

interface Props extends CognitoProps, BreadcrumbProps {}

const AdminRoute: React.FC<Props> = (props: Props) => {
  const { signOut } = props;

  const user = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.groups?.some((group) => group !== "admin")) navigate("/");
  }, [user]);

  const responseUser = useApi("/getUser", {});

  if (responseUser === "loading") {
    return <Loading />;
  }

  if (isError(responseUser)) {
    return <ErrorAlert message={responseUser.message} />;
  }
  return (
    <Suspense fallback={<Loading />}>
      <Header
        signOut={() => {
          signOut && signOut();
        }}
      />

      <Sidebar
        signOut={() => {
          signOut && signOut();
        }}
      />
      <main className="p-4 md:ml-64 h-auto pt-20">
        <Routes>
          <Route path={`/`} element={<AdminHome {...props} />} />
          <Route path={`/residences`} element={<Residences {...props} />} />
          <Route path="*" element={<ErrorNotFound />} />
        </Routes>
      </main>
    </Suspense>
  );
};

export default AdminRoute;
