import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { Provider as AlertProvider, positions, transitions } from "react-alert";
import "./App.css";
import "./scss/common.scss";

import amplifyConfig from "./aws-exports";
import { AlertTemplate } from "./components/Alert";
import MainRoute from "./routes/MainRoute";

Amplify.configure(amplifyConfig);

export interface CognitoProps {
  signOut?: () => void;
}

function App() {
  const alertOptions = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: "53px",
    transition: transitions.SCALE,
  };

  return (
    <Authenticator>
      {({ signOut }) => (
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <MainRoute
            signOut={() => {
              signOut && signOut();
            }}
          />
        </AlertProvider>
      )}
    </Authenticator>
  );
}

export default App;
