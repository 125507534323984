import { Auth } from "aws-amplify";
import Axios, { AxiosRequestConfig } from "axios";
import { useMemo } from "react";
import useSWR, { KeyedMutator, SWRConfiguration } from "swr";
import { isError } from "../helper/error";
import { Endpoint, Responses } from "../types/api";
import { apiURL } from "./urls";

export interface SWRResponse<K> {
  refetch: KeyedMutator<K>;
}

export default function useApi<K extends Endpoint, T extends {}>(
  endpoint: K,
  request?: T,
  options?: { skipDebug?: boolean; swrConfig?: SWRConfiguration }
): Loading<(Responses[K] & SWRResponse<Responses[K] | Error>) | Error> {
  const url = apiURL(endpoint);

  const { data, mutate, isLoading } = useSWR(
    [url, request],
    async (url: any, request: any) => {
      const user = await Auth.currentSession();

      const path = url[0];

      const requestConfig: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${user.getIdToken().getJwtToken()}`,
        },
        method: "GET",
        url: path,
        data: request,
      };

      const res = await Axios.request<any>(requestConfig);

      if (res.status !== 200) {
        return Error(res.data?.message || "Unknown Error");
      }

      return res.data;
    },
    { revalidateOnFocus: false, ...options?.swrConfig }
  );

  return useMemo(() => {
    if (!data) {
      return "loading";
    }

    if (isError(data)) {
      return data;
    }

    return { ...data, refetch: mutate };
  }, [data, mutate]);
}
